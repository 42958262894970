// Import necessary dependencies
import React from 'react';
import './App.css';

// Import images or other assets
import logo from './logo.svg';
import profileImage from './profile.png';

// Functional component for the portfolio
function App() {
  return (
    <div className="App">
      {/* Header section */}
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Your Name</h1>
        <p>Web Developer</p>
      </header>

      {/* About Me section */}
      <section className="about-me">
        <h2>About Me</h2>
        <img src={profileImage} alt="Profile" />
        <p>
          Welcome to my portfolio! I am a passionate web developer with expertise in
          React, JavaScript, and other web technologies. I love creating responsive and
          user-friendly web applications.
        </p>
      </section>

      {/* Projects section */}
      <section className="projects">
        <h2>Projects</h2>
        {/* Add your project components here */}
        <div className="project">
          <h3>Project 1</h3>
          <p>Description of Project 1.</p>
        </div>
        <div className="project">
          <h3>Project 2</h3>
          <p>Description of Project 2.</p>
        </div>
        {/* Add more projects as needed */}
      </section>

      {/* Contact section */}
      <section className="contact">
        <h2>Contact Me</h2>
        <p>Email: your.email@example.com</p>
        <p>LinkedIn: linkedin.com/in/yourname</p>
        <p>GitHub: github.com/yourusername</p>
      </section>
    </div>
  );
}

export default App;
